<template lang="pug">
  div(class="float-right")
    button.btn.btn-sm.btn-primary(type="button" :disabled="!enabled" @click="read()")
      fa(icon="pencil-alt")

    button.btn.btn-sm.btn-danger.ml-1(type="button" :disabled="!enabled" @click="remove()" v-if="showRemove")
      fa(icon="trash")

    slot
</template>

<script>
export default {
  props: {
    prefix: {
      type: String,
      default: 'global'
    },
    object: {
      type: Object,
      default: function() {
        return {}
      }
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {}
  },

  methods: {
    read() {
      this.$emit('open');
    },
    remove() {
      this.$emit('remove')
    }
  }
}
</script>

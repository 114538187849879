<template lang="pug">
  span {{ humanized }}
</template>

<script>
import { Duration } from 'luxon';

export default {
  props: {
    inSeconds: {
      type: Number,
      default: 0
    },
    format: {
      type: String,
      default: "h'h' m'm' s's'"
    }
  },

  data() {
    return {}
  },

  computed: {
    humanized() {


      return Duration.fromObject({ seconds: this.inSeconds }).toFormat(this.format);
    }
  }
}
</script>
